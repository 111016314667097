import React from 'react'
import getPromos from '../../../actions/plans/getPromos'
import getLoyaltyPrograms from '../../../actions/plans/getLoyaltyPrograms'
import history from '../../../history'
import tableColumnsChange from '../../../utils/tableColumnsChange'
import { connect } from 'react-redux'
import matchSorter from 'match-sorter'
import moment from 'moment'
import StandardTable from '../../Shared/StandardTable.js'
import { changeModule } from '../../../actions/changeModule'
import isEmpty from 'lodash/isEmpty'
import SectionSelect from '../../Shared/SectionSelect'

const tableColumns = [{
  Header: 'Name',
  id: 'Name',
  accessor: properties => properties.promo_name || properties.loyalty_program_name,
  filterMethod: (filter, rows) => (
    matchSorter(rows, filter.value, { keys: ['Name'] })
  ),
  filterAll: true,
}, {
  Header: 'Code',
  id: 'Code',
  accessor: properties => properties.promo_code || properties.loyalty_program_code,
  filterMethod: (filter, rows) => (
    matchSorter(rows, filter.value, { keys: ['Code'] })
  ),
  filterAll: true,
}, {
  Header: 'Description',
  id: 'Description',
  accessor: properties => properties.promo_desc || properties.loyalty_program_desc,
  filterMethod: (filter, rows) => (
    matchSorter(rows, filter.value, { keys: ['Description'] })
  ),
  filterAll: true,
},
{
  Header: 'Terms Description Present',
  id: 'DescriptionPresent',
  accessor: properties => {
    if (!isEmpty(properties.promo_terms_desc) || !isEmpty(properties.loyalty_program_terms_desc)) return 'Yes'
    return 'No'
  },
  filterMethod: (filter, rows) => (
    matchSorter(rows, filter.value, { keys: ['DescriptionPresent'] })
  ),
  filterAll: true,
},
{
  Header: 'Redemption Required',
  id: 'RedemptionRequired',
  accessor: d => { return d.redemption_required ? 'Yes' : 'No' },
  filterMethod: (filter, rows) => (
    matchSorter(rows, filter.value, { keys: ['RedemptionRequired'] })
  ),
  filterAll: true,
}, {
  Header: 'Type',
  id: 'Type',
  accessor: d => { return d.loyalty_program_name ? 'Loyalty Program' : 'Promo' },
  filterMethod: (filter, rows) => (
    matchSorter(rows, filter.value, { keys: ['Type'] })
  ),
  filterAll: true,
}, {
  Header: 'Last Sold Date',
  id: 'LastSoldDate',
  accessor: 'last_sold_dt',
  filterMethod: (filter, rows) => (
    matchSorter(rows, filter.value, { keys: ['LastSoldDate'] })
  ),
  filterAll: true
}, {
  Header: 'Measure',
  id: 'Measure',
  accessor: props => props.promo_measure || props.loyalty_program_measure,
  filterMethod: (filter, rows) => (
    matchSorter(rows, filter.value, { keys: ['Measure'] })
  ),
  filterAll: true
}, {
  Header: 'Maximum Reward Amount',
  id: 'MaximumRewardAmount',
  accessor: 'max_reward_amount',
  filterMethod: (filter, rows) => (
    matchSorter(rows, filter.value, { keys: ['MaximumRewardAmount'] })
  ),
  filterAll: true
}, {
  Header: 'Fulfillment Vendor Code',
  id: 'FullfillmentVendorCode',
  accessor: 'fulfillment_vendor_code',
  filterMethod: (filter, rows) => (
    matchSorter(rows, filter.value, { keys: ['FullfillmentVendorCode'] })
  ),
  filterAll: true
},
{
  Header: 'Updated at',
  id: 'UpdatedAt',
  accessor: props => {
    if (props.history) {
      return new Date(props.history.updated_at).toLocaleString('en-US')
    }
  },
  filterMethod: (filter, rows) => (
    matchSorter(rows, filter.value, { keys: ['UpdatedAt'] })
  ),
  filterAll: true
},
{
  Header: 'Updated by',
  id: 'UpdatedBy',
  accessor: props => { if (props.updated_by_user) return props.updated_by_user.first_name + ' ' + props.updated_by_user.last_name },
  filterMethod: (filter, rows) => (
    matchSorter(rows, filter.value, { keys: ['UpdatedBy'] })
  ),
  filterAll: true
},
{
  Header: 'Edit',
  accessor: 'edit',
  Cell: () => <button className="btn btn-primary-outline rounded" type="button">Edit</button>,
},
{
  Header: 'Can Ad Hoc',
  id: 'CanAdHoc',
  accessor: properties => properties.can_adhoc === undefined ? 'N/A' : properties.can_adhoc ? 'True' : 'False',
  filterMethod: (filter, rows) => (
    matchSorter(rows, filter.value, { keys: ['CanAdHoc'] })
  ),
  filterAll: true
},
]

class RewardsContainer extends React.Component {
  constructor() {
    super()
    this.state = {
      tableColumns: tableColumns,
    }
  }

  componentDidMount() {
    this.props.changeModule('Rewards')
    this.props.getLoyaltyPrograms()
    this.props.getPromos()
  }

  rewards() {
    return this.props.loyalty_programs.concat(this.props.promos)
  }


  tableColumnOptions(columns) {
    return columns.map(column => {
      return {
        label: column.Header,
        value: column
      }
    })
  }

  onColumnsChange = (options) => {
    const updatedTableColumns = tableColumnsChange(options, tableColumns)
    this.setState({ tableColumns: updatedTableColumns })
  }

  handleSectionSelect = (reward) => {
    const urlSuffix = reward.loyalty_program_code ? `LoyaltyProgram/${reward.loyalty_program_code}` : `Promo/${reward.promo_code}`
    window.open(`/rewards/edit${urlSuffix}`, '_blank', 'noopener, noreferrer')

  }

  create = () => {
    history.push('/rewards/new')
  }

  render() {
    if (this.props.loading) return <div>Loading</div>

    const rewards = this.rewards()

    return (
      <div className="container-fluid">
        <div className="row">
          <h4>Rewards</h4>
        </div>
        <div className="row">
          <button className="btn btn-md btn-primary mb-3" onClick={this.create}>+ Create</button>
        </div>
        {
          rewards.length > 0 ?
            <StandardTable
              columns={this.state.tableColumns}
              data={rewards}
              height="600px"
              onSelect={this.handleSectionSelect}
            />
            :
            <p>No rewards</p>
        }
        <SectionSelect
          multi
          label="Show Columns"
          labelKey="label"
          options={this.tableColumnOptions(tableColumns)}
          value={this.tableColumnOptions(this.state.tableColumns)}
          valueKey="value"
          onChange={(value) => this.onColumnsChange(value)}
        />
      </div >
    )
  }
}

function mapStateToProps(state) {
  return {
    loyalty_programs: state.planFeatures.loyaltyPrograms,
    promos: state.planFeatures.promos,
  }
}

const mapDispatchToProps = {
  changeModule,
  getLoyaltyPrograms,
  getPromos,
}

export default connect(mapStateToProps, mapDispatchToProps)(RewardsContainer)
